import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-monty-hall',
    templateUrl: './monty-hall.component.html',
    styleUrls: ['./monty-hall.component.scss']
})

export class MontyHallComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
